import { lazy, Suspense } from "preact/compat";
import Preloader from "./components/Preloader";
import "./style";

import "./i18n";

const LazyApp = lazy(() => import('./components/App')
  .then( module => {
    console.log("loaded")
    return new Promise( (resolve) => {
      module.onAuthStateChangedHandler( () => {
        console.log("cold run finishing");
        resolve(module);
      });
    });
  }) );

export default () => (
  <Suspense fallback={<Preloader/>}>
    <LazyApp />
  </Suspense>
);
